
.user .scope .name{
    position: relative;
    float: right;
    padding: 9px 22px;
    border-radius: 30px;
}

.user .scope .select{
    position: relative;
    float: right;
}

.user .picture img{
    border-radius: 120px;
    border: 0.5px solid black;
}