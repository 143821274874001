
.menu{
    max-height: 95vh;
    overflow: auto;
}

.background-image{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -40;
    max-height: 100vh;
    width: 100%;
    filter: blur(12px);
}