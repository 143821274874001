@media (prefers-reduced-motion: no-preference) {
    .spin {
        animation: spin-animation infinite 20s linear;
    }
}

@keyframes spin-animation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
