
.clickable:hover{
    cursor: grab;
    transform: scale(1.05);
}

.clickable{
    cursor: pointer;
    transform: scaleY(1) scaleX(1);
    transition-duration: 0.5s;
}

.clickable * {
    pointer-events: none;
}

.disabled{
    cursor: not-allowed;
}

@media (prefers-reduced-motion: no-preference) {
    .spin {
        animation: spin-animation infinite 20s linear;
    }
}

@keyframes spin-animation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}